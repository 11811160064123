<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import layout from "simple-keyboard-layouts/build/layouts/farsi";
export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String,
    },
    input: {
      type: String,
    },
    layout: layout,
  },
  data: () => ({
    keyboard: null,
  }),
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      ...layout,
      layout: {
        default: [
          "{bksp} 0 9 8 7 6 5 4 3 2 1",
          "چ ج ح خ ه ع غ ف ق ث ص ض",
          "گ ک م ن ت ا ل ب ی س ش",
          "و پ د ذ ر ز ژ ط ظ آ ئ ء",
          "{space}",
        ],
        shift: [""],
      },
      theme: "hg-theme-default hg-layout-default myTheme",
      display: {
        "{bksp}": "⌦",
        "{shift}": "تبدیل",
        "{space}": "فاصله",
      },
    });
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.simple-keyboard {
  width: 100% !important;
}
.simple-keyboard.hg-theme-default.myTheme {
  background-color: #137384b3 !important;
  border-radius: 10px;
}

.simple-keyboard.hg-layout-default .hg-button.hg-red {
  background: rgb(255, 0, 0, 0.7);
  color: white;
}

.simple-keyboard .hg-button {
  box-shadow: none !important;
  z-index: 1;
  padding: 4rem !important;
  margin: 3px !important;
  border: none !important;
  border-radius: 10px !important;
  span {
    font-size: 2.5rem !important;
  }
}
.simple-keyboard {
  .hg-button-bksp {
    background-color: #d9534f !important;
    color: white;
    span {
      font-size: 3rem !important;
      margin-top: 6px;
    }
  }
  .hg-button-shift {
    background-color: #4d455d !important;
    color: white;
  }
}
</style>
