<template>
  <div class="patient-panel">
    <div
      class="patient-login app login-page flex-row align-items-center element"
    >
      <v-row class="justify-content-center">
        <v-col cols="10" sm="10" md="10" lg="10">
          <v-row class="center-row">
            <v-card class="cards pa-5 login-card">
              <!-- login div -->
              <div v-if="!codeSent" class="login ps-3 pe-3 cards pa-5">
                <h6
                  class="text-center mb-10 login-text"
                  style="line-height: 26px"
                >
                  خواهشمند است برای ورود به سیستم و دریافت کد کاربری از طریق
                  پیامک، شماره همراه خود را وارد کنید.
                </h6>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="mobile"
                    class="login-input"
                    append-icon="phone_iphone"
                    type="number"
                    label="شماره تلفن همراه"
                    @keyup.enter="
                      mobile && mobile.length >= 11 && !isBusy ? sendCode() : ''
                    "
                  ></v-text-field>
                  <!-- numeric keypad -->
                  <VueNumericKeypad
                    id="mobile"
                    :value.sync="mobile"
                    :show="true"
                    :options="options"
                  />
                </v-col>

                <v-row class="mt-1 ps-2 pe-2">
                  <v-col class="text-center">
                    <v-btn
                      type="submit"
                      class="primary-btn w-100 py-10 font-weight-bold"
                      @click="sendCode()"
                      style="border: 0"
                      :loading="isBusy"
                      :disabled="!mobile || mobile.length < 11"
                    >
                      <v-icon small class="btn-text me-1">send</v-icon>
                      ارسال کد فعالسازی
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <div v-if="!choosePatient && codeSent">
                <div class="text-center mb-1 mt-1">
                  <h5 class="login-text mb-3">
                    کد ارسال شده از طریق پیامک را وارد کنید.
                  </h5>
                  <span class="text--secondary mt-2" style="font-size: 14px">
                    کد تایید به شماره
                    {{ mobile }}
                    ارسال شده است.
                  </span>
                </div>
                <div class="input-wrapper pa-5 text-center">
                  <h4 dir="ltr" class="d-flex justify-content-center">
                    <span
                      class="code-container"
                      v-for="(char, index) in activationCodeArray"
                      :key="index"
                      >{{ char }}
                    </span>
                  </h4>
                  <v-text-field
                    v-model="activationCode"
                    type="number"
                    v-show="false"
                  >
                  </v-text-field>
                  <!-- numeric keypad -->
                  <VueNumericKeypad
                    id="activationCode"
                    :value.sync="activationCode"
                    :show="true"
                    :options="options"
                  />
                  <v-progress-linear
                    v-if="busy"
                    indeterminate
                    color="primary"
                    class="mt-5"
                  ></v-progress-linear>
                </div>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-btn
                      type="submit"
                      class="second-btn py-8 ps-14 pe-14"
                      @click="sendCode()"
                      style="width: 90%"
                      :disabled="sendAgainDisbaled || isBusy"
                      ><span class="mt-2">
                        <v-icon small left>textsms</v-icon>ارسال مجدد کد
                        <span v-if="countdown != 0">{{
                          toHHMMSS(countdown)
                        }}</span>
                      </span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-btn
                      v-if="codeSent"
                      type="submit"
                      @click="changeNumber()"
                      :disabled="busy"
                      class="primary-btn py-8 ps-14 pe-14 float-start"
                      style="width: 90%"
                    >
                      <v-icon small left>smartphone</v-icon>تغییر شماره موبایل
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <div
                v-if="choosePatient"
                class="pa-3"
                style="max-height: 600px; overflow-y: auto; width: 700px"
              >
                <div class="text-right mb-10 d-flex justify-space-between">
                  <h6 class="font-weight-bold mt-2">
                    <v-icon right class="primary--text"> schedule </v-icon>
                    <span>{{ toHHMMSS(chooseUserCounter) }} </span>
                    زمان باقی مانده است تا بیمار مورد نظر خود را انتخاب کنید.
                  </h6>
                  <v-btn class="red-btn py-7 ps-6 pe-6" @click="resetPage()">
                    بازگشت
                    <v-icon class="ms-2">mdi-arrow-left</v-icon>
                  </v-btn>
                </div>
                <div v-for="(user, index) in users" :key="index" class="mt-5">
                  <v-row class="pa-2">
                    <v-col>
                      <h5 class="mt-3">
                        <span class="primary--text"> نام کاربر : </span>
                        {{ user.name }}
                      </h5>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                      <v-btn
                        class="primary-btn ps-10 pe-10 py-8"
                        @click="goToDashboard(user.id)"
                      >
                        <v-icon left>mdi-login</v-icon>
                        <span class="h2">ورود</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <hr />
                </div>
                <div
                  class="mt-6 pa-5 second-btn text-center pointer"
                  @click="
                    newPatientDialog = true;
                    inputs = { input1: '', input2: '' };
                  "
                >
                  <v-icon class="white--text" large>mdi-plus</v-icon>
                  <span class="h2"> افزودن عضو جدید خانواده </span>
                </div>
              </div>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog width="1200" v-model="newPatientDialog" persistent>
        <v-card class="cards py-3">
          <v-card-title>
            <h3>افزودن عضو جدید</h3>
          </v-card-title>
          <v-card-text>
            <br />
            <v-row class="mb-5">
              <v-col v-for="key in Object.keys(inputs)" :key="key">
                <Input
                  :inputs="inputs"
                  :inputName="key"
                  @onInputFocus="onInputFocus"
                  @onInputChange="onInputChange"
                />
              </v-col>
            </v-row>
            <!-- on screen keyboard -->
            <SimpleKeyboard
              @onChange="onChange"
              @onKeyPress="onKeyPress"
              :input="inputs[inputName]"
              :inputName="inputName"
            />

            <div class="mt-6">
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!inputs[inputName]"
                @click="addPatient()"
                width="100"
                :loading="addPatientBtnLoading"
                class="primary-btn py-6"
                outlined
              >
                افزودن
              </v-btn>
              <v-btn
                class="red-btn ms-2 py-6"
                width="100"
                outlined
                :loading="removeBusy"
                @click="noPatient ? removeUser() : (newPatientDialog = false)"
              >
                لغو
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import PincodeInput from "vue-pincode-input";
import VueNumericKeypad from "vue-numeric-keypad";
import SimpleKeyboard from "./SimpleKeyboard";
import Input from "./Input";

export default {
  name: "Login",
  components: {
    PincodeInput,
    VueNumericKeypad,
    SimpleKeyboard,
    Input,
  },
  data() {
    return {
      mobile: "",
      busy: false,
      isBusy: false,
      codeSent: false,
      choosePatient: false,
      activationCode: "",
      countdown: 60,
      chooseUserCounter: 300,
      users: [],
      mainToken: "",
      sendAgainDisbaled: true,
      options: {
        keyRandomize: false,
        randomizeClick: true,
        fixDeleteKey: false,
      },
      newPatientDialog: false,
      newPatient: {},
      addPatientBtnLoading: false,
      noPatient: false,
      currentPatientId: "",
      show: true,
      input: "",
      inputs: {
        input1: "",
        input2: "",
      },
      inputName: "input1",
      removeBusy: false,
    };
  },
  computed: {
    activationCodeArray() {
      if (this.activationCode.length >= 5) {
        this.activationCode = this.activationCode.substring(0, 5);
        this.login();
      }
      let myCode = new Array(5).fill(0);
      myCode.forEach((char, index) => {
        myCode[index] = this.activationCode[index];
      });
      return myCode;
    },
    //inputValue: function () {
    //  return this.newPatient[this.inputName];
    //}
  },
  methods: {
    ...mapMutations(["setUserName", "setRole"]),
    goToDashboard(patientId) {
      this.busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patientPanel/auth/entrance",
          {
            patientId: patientId,
          },
          {
            headers: {
              Authorization: "Bearer: " + this.mainToken,
            },
          }
        )
        .then((res) => {
          this.busy = false;
          if (res.status == 200) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("patientId", res.data.patientId);
            this.setUserName(res.data.name);
            this.$router.push("/patientPanel/newRequest");
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    sendCode() {
      this.isBusy = true;
      this.$http
        .post(this.baseUrl + "/clinic/patientPanel/auth/getCode", {
          mobile: this.mobile,
        })
        .then((res) => {
          this.codeSent = true;
          this.activationCode = "";
          this.countdown = 60;
          this.countDownTimer();

          this.isBusy = false;
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    login() {
      if (this.activationCode.length == 5) {
        this.busy = true;
        this.$http
          //post(this.baseUrl + "/clinic/auth/patientEntrance"
          .post(this.baseUrl + "/clinic/patientPanel/auth/login", {
            mobile: this.mobile,
            activationCode: this.activationCode,
          })
          .then((res) => {
            this.busy = false;
            if (res.status == 200) {
              //localStorage.clear();
              if (res.data.patients.patientId) {
                this.noPatient = true;
                this.currentPatientId = res.data.patients.patientId;
                this.newPatientDialog = true;
                this.newPatient = {};
              } else {
                this.users = res.data.patients;
                this.choosePatient = true;
                this.chooseUserCounter = 300;
                this.chooseUserTimer();
                this.mainToken = res.data.token;
              }
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.busy = false;
            this.toast("خطا: " + err.response.data, "error");
          });
      }
    },
    getPatiensList() {
      this.busy = true;
      this.$http
        //post(this.baseUrl + "/clinic/auth/patientEntrance"
        .post(this.baseUrl + "/clinic/patientPanel/patient/getAll", {
          mobile: this.mobile,
        })
        .then((res) => {
          this.busy = false;
          if (res.status == 200) {
            //localStorage.clear();
            this.users = res.data;
            this.choosePatient = true;
            this.chooseUserCounter = 300;
            this.chooseUserTimer();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    changeNumber() {
      this.codeSent = false;
    },
    toHHMMSS(value) {
      // NOTE (m-toHHMMSS) determine the order of hours, minutes and seconds
      var sec_num = parseInt(value, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes + ":" + seconds;
    },
    countDownTimer() {
      //NOTE (m-countDownTimer) to start counting down after 1 second
      if (this.countdown > 0 && this.codeSent) {
        this.sendAgainDisbaled = true;
        setTimeout(() => {
          this.countdown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.countdown = 0;
        this.sendAgainDisbaled = false;
      }
    },
    chooseUserTimer() {
      //NOTE (m-countDownTimer) to start counting down after 1 second
      if (this.chooseUserCounter > 0 && this.choosePatient) {
        setTimeout(() => {
          this.chooseUserCounter -= 1;
          this.chooseUserTimer();
        }, 1000);
      } else {
        this.chooseUserCounter = 0;
        this.codeSent = false;
        this.choosePatient = false;
        this.mobile = "";
      }
    },
    addPatient() {
      this.addPatientBtnLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patientPanel/patient/add",
          {
            mobile: this.mobile,
            firstName: this.inputs.input1,
            lastName: this.inputs.input2,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.toast("بیمار با موفقیت ثبت شد.", "success");
            this.newPatientDialog = false;
            this.addPatientBtnLoading = false;
            if (this.noPatient) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("patientId", res.data.patientId);
              this.setUserName(res.data.name);
              this.$router.push("/patientPanel/newRequest");
            } else {
              this.getPatiensList();
            }
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.addPatientBtnLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.addPatientBtnLoading = false;
        });
    },
    resetPage() {
      this.newPatientDialog = false;
      this.mobile = "";
      this.busy = false;
      this.isBusy = false;
      this.codeSent = false;
      this.noPatient = false;
      this.choosePatient = false;
      this.inputs = {
        input1: "",
        input2: "",
      };
    },
    removeUser() {
      this.removeBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patientPanel/patient/delete",
          {
            patientId: this.currentPatientId,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.removeBusy = false;
          if (res.status == 200) {
            this.resetPage();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.removeBusy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    onChange(input) {
      this.inputs[this.inputName] = input;
    },
    onKeyPress(button) {
      //console.log("button", button);
    },
    onInputChange(input) {
      //console.log("Input changed directly:", input.target.id);
      this.inputs[input.target.id] = input.target.value;
    },
    onInputFocus(input) {
      //console.log("Focused input:", input.target.id);
      this.inputName = input.target.id;
    },
  },
};
</script>
