<template>
  <div>
    <v-text-field
      :id="inputName"
      v-model="inputValue"
      :label="computedLabel"
      @focus="onInputFocus"
      @input="onInputChange"
      outlined
      hide-details
      :autofocus="inputName == 'firstName' ? true : false"
    ></v-text-field>

    <h6
      class="red--text mt-2"
      v-if="inputName == 'nationalCode' && checkNatCode(inputValue)"
    >
      <v-icon class="red--text">mdi-alert-circle-outline</v-icon>
      کدملی وارد شده صحیح نمی‌باشد.
    </h6>
  </div>
</template>

<script>
function checkCodeMeli(code) {
  var L = code.length;

  if (L < 8 || parseInt(code, 10) == 0) return false;
  code = ("0000" + code).substr(L + 4 - 10);
  if (parseInt(code.substr(3, 6), 10) == 0) return false;
  var c = parseInt(code.substr(9, 1), 10);
  var s = 0;
  for (var i = 0; i < 9; i++) s += parseInt(code.substr(i, 1), 10) * (10 - i);
  s = s % 11;
  return (s < 2 && c == s) || (s >= 2 && c == 11 - s);
}
export default {
  name: "Input",
  props: {
    inputs: {
      type: Object,
    },
    inputName: {
      type: String,
    },
  },
  methods: {
    checkNatCode(id) {
      return id ? (checkCodeMeli(id) ? false : true) : false;
    },
    onInputChange(input) {
      this.$emit("onInputChange", input);
    },
    onInputFocus(input) {
      this.$emit("onInputFocus", input);
    },
  },
  computed: {
    inputValue: function () {
      return this.inputs[this.inputName];
    },
    computedLabel() {
      let text = "";
      switch (this.inputName) {
        case "firstName":
          text = "نام";
          break;
        case "lastName":
          text = "نام خانوادگی";
          break;
        case "mobile":
          text = "شماره موبایل";
          break;
        case "nationalCode":
          text = "کد ملی";
          break;
      }
      return text;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.v-text-field {
  .v-label {
    font-size: 2rem !important;
  }
  input {
    font-size: 2rem !important;
  }
}
</style>
